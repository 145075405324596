<template>
  <div class="row" id="setting_notes">
    <div class="col-md-12">
      <div class="card form-body card-custom gutter-b example example-compact">
        <div
          class="card-header"
          style="
    position: sticky;
    top: 99px;
    z-index: 9;
    background: white;
"
        >
          <h5 class="card-title">
            Notes
          </h5>

          <button
            ref="kt_update_note"
            type="reset"
            class="btn btn-success mr-2"
            style="height: 36px;float: right;margin-top: 18px;"
            @click="updateNote()"
          >
            Update
          </button>
        </div>
        <div class="card-body">
          <vDataLoader v-if="dataLoading"></vDataLoader>
          <form v-else>
            <div class="form-group">
              <label>Invalid Abbreviation Code Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="invalid_abbr"
                :init="config"
              />
            </div>

            <div class="form-group">
              <label>Expired Abbreviation Code Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="expired_abbr"
                :init="config"
              />
            </div>
            <!-- <div class="form-group">
              <label>Booknow Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="booknow_note"
                :init="config"
              />
            </div> -->

            <div class="form-group">
              <label>Abbreviation Code Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="abbreviation_code_note"
                :init="config"
              />
            </div>

            <div class="form-group">
              <label>No Schedule Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="no_schedule_note"
                :init="config"
              />
            </div>
            <div class="form-group">
              <label>Personal Identity Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="personal_identity_note"
                :init="config"
              />
            </div>

            <div class="form-group">
              <label>Appointment Confirm Detail Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="appointment_confirm_detail_note"
                :init="config"
              />
            </div>

            <div class="form-group">
              <label>Appointment Shipping Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="appointment_shipping_note"
                :init="config"
              />
            </div>

            <div class="form-group">
              <label>Fbi Check Disclosure Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="fbi_check_disclosure_note"
                :init="config"
              />
            </div>

            <div class="form-group">
              <label>Finra Disclosure Note</label>

              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="finra_disclosure_note"
                :init="config"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { FETCH_NOTES, UPDATE_NOTES } from "@/core/services/store/actions.type";
import Editor from "@tinymce/tinymce-vue";
import vDataLoader from "@/components/frontend/spinner.vue";

export default {
  components: {
    vDataLoader,
    editor: Editor
  },
  name: "app",
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Setting",
          route: ""
        },
        {
          id: 3,
          title: "Notes",
          route: ""
        }
      ],
      editor: ClassicEditor,

      booknow_note: "",
      abbreviation_code_note: "",
      no_schedule_note: "",
      personal_identity_note: "",
      appointment_confirm_detail_note: "",
      appointment_shipping_note: "",
      finra_disclosure_note: "",
      fbi_check_disclosure_note: "",
      expired_abbr: "",
      invalid_abbr: "",
      dataLoading: true,

      id: 0,
      config: {
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | formatselect | fontsizeselect |bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | code | help",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt"
      }
    };
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getNote();
  },

  methods: {
    getNote() {
      this.dataLoading = true;
      this.$store
        .dispatch(FETCH_NOTES)
        .then(res => {
          var data = res.data;
          this.id = data.id ? data.id : 0;
          this.abbreviation_code_note = data.abbreviation_code_note;
          this.no_schedule_note = data.no_schedule_note;
          this.personal_identity_note = data.personal_identity_note;
          this.appointment_confirm_detail_note =
            data.appointment_confirm_detail_note;
          this.appointment_shipping_note = data.appointment_shipping_note;
          this.fbi_check_disclosure_note = data.fbi_check_disclosure_note;
          this.finra_disclosure_note = data.finra_disclosure_note;
          this.expired_abbr = data.expired_abbr;
          this.invalid_abbr = data.invalid_abbr;
          this.dataLoading = false;
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    updateNote() {
      const submitButton = this.$refs["kt_update_note"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      var data = {
        form_data: {
          abbreviation_code_note: this.abbreviation_code_note,
          no_schedule_note: this.no_schedule_note,
          personal_identity_note: this.personal_identity_note,
          appointment_confirm_detail_note: this.appointment_confirm_detail_note,
          appointment_shipping_note: this.appointment_shipping_note,
          fbi_check_disclosure_note: this.fbi_check_disclosure_note,
          finra_disclosure_note: this.finra_disclosure_note,
          expired_abbr: this.expired_abbr,
          invalid_abbr: this.invalid_abbr
        },
        id: this.id
      };
      this.$store.dispatch(UPDATE_NOTES, data).then(res => {
        this.id = res.i_id;
        this.$toastr.s(res.msg);
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      });
    }
  }
};
</script>
